@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Teko:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400&display=swap');




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}



.nav-bar-section {

    height: 80px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);





}

.nav-bar-section .container {
    padding-top: 13px;
}

.nav-bar-section .logo-div {

    width: 230px;
    height: 150px;

    margin-top: -51px;

    text-align: left;



}

.nav-bar-section .logo-div img {
    height: 95%;
    width: 70%;


}

.navbar-toggler {
    color: white !important;

    border: 3px solid white;

}

.navbar-toggler-icon {
    background-color: white !important;

}


.nav-link {
    text-align: center;
    margin-left: 14px;
    font-family: "Josefin sans";
    line-height: 26px;
    font-weight: 500;
    font-style: normal;
    color: black;
    font-size: 20px;
    margin-top: -53px;


}

.nav-bar-section.scrolled {
    background-color: whitesmoke;
}

.nav-button {
    position: relative;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    padding: 10px 20px;
    margin-top: -30px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 30px;
    margin-right: -30px;
}

button {
    position: relative;
    background-color: #FF8C00;
    padding: 5px 15px;
    margin-top: -30px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 7px;
}

.navlink {
    font-weight: 500;
    text-decoration: none;
    font-family: "Josefin sans";
}

.nav-item a button span {
    color: white;
}

@media only screen and (max-device-width: 568px) {

    .nav-bar-section {

        height: 100px;

    }

    .nav-bar-section .logo-div {

        width: 100px;
        margin-top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;

    }

    .nav-bar-section .logo-div img {
        height: 160px;
        width: 160px;
        margin-top: 30px;

    }

    .nav-bar-section .container {
        padding-top: 0px;

    }


    .navbar-toggler-icon {
        background-color: white !important;
    }

    .navbar-collapse {
        background-color: white;
        text-align: center;
        margin-top: 0px;
    }

    .nav-link {
        color: black;
        margin-top: 0px;
    }

    .navbar-nav .nav-item.active {
        background-color: whitesmoke;
    }

    .navbar-nav .nav-item.active .nav-link {
        color: black;
    }

    /* Adjust margin and padding for the "Donate Now" button */
    .nav-button {
        margin-left: -30px;
        /* Move the button to the right */
        margin-top: 7px;
        /* Adjust vertical spacing */
        padding: 10px 20px;
        /* Adjust button padding */
    }
}







/* Add this media query for mobile devices */
@media only screen and (max-width: 767px) {

    .nav-bar-section {

        height: 100px;

    }

    .nav-bar-section .logo-div {

        width: 100px;
        margin-top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;

    }

    .nav-bar-section .logo-div img {
        height: 160px;
        width: 160px;
        margin-top: 30px;

    }

    .nav-bar-section .container {
        padding-top: 0px;

    }


    .navbar-toggler-icon {
        background-color: white !important;
    }

    .navbar-collapse {
        background-color: white;
        text-align: center;
        margin-top: 0px;
    }

    .nav-link {
        color: black;
        margin-top: 0px;
    }

    .navbar-nav .nav-item.active {
        background-color: whitesmoke;
    }

    .navbar-nav .nav-item.active .nav-link {
        color: black;
    }

    /* Adjust margin and padding for the "Donate Now" button */
    .nav-button {
        margin-left: -30px;
        /* Move the button to the right */
        margin-top: 7px;
        /* Adjust vertical spacing */
        padding: 10px 20px;
        /* Adjust button padding */
    }
}




@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {

    .nav-bar-section {

        height: 90px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);





    }

    .nav-bar-section .container {
        padding-top: 16px;

        padding-left: -0px;
        padding-right: -0px;
    }

    .nav-bar-section .logo-div {

        width: 260px;
        height: 150px;
        margin-left: -65px;
        margin-top: -51px;

        text-align: right;




    }

    .nav-bar-section .logo-div img {
        height: 95%;
        width: 80%;


    }

    .navbar-toggler {
        color: white !important;

        border: 3px solid white;

    }

    .navbar-toggler-icon {
        background-color: white !important;

    }




    .nav-link {
        text-align: center;
        display: inline-block;
        margin-left: 10px;
        white-space: nowrap;
        font-family: "Josefin sans";
        line-height: 18px;
        font-weight: 500;
        font-style: normal;
        color: black;
        font-size: 20px;
        margin-top: -40px;




    }

    .nav-bar-section.scrolled {
        background-color: whitesmoke;
    }

    .nav-button {
        position: relative;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        padding: 10px 20px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 30px;
        margin-right: -30px;
    }

    button {
        position: relative;
        background-color: #FF8C00;
        padding: 5px 15px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 7px;
    }

    .navlink {
        font-weight: 500;
        margin-left: 1px;

        text-decoration: none;
        font-family: "Josefin sans";

    }

    .nav-item a button span {
        color: white;
    }



}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

    .nav-bar-section {

        height: 90px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);





    }

    .nav-bar-section .container {
        padding-top: 16px;

    }

    .nav-bar-section .logo-div {

        width: 230px;
        height: 150px;
        margin-left: -45px;
        margin-top: -51px;

        text-align: left;




    }

    .nav-bar-section .logo-div img {
        height: 95%;
        width: 70%;


    }

    .navbar-toggler {
        color: white !important;

        border: 3px solid white;

    }

    .navbar-toggler-icon {
        background-color: white !important;

    }




    .nav-link {
        text-align: center;
        display: inline-block;
        margin-left: 10px;

        font-family: "Josefin sans";
        line-height: 18px;
        font-weight: 500;
        font-style: normal;
        color: black;
        font-size: 20px;
        margin-top: -40px;
        background-color: rebeccapurple;
        white-space: nowrap;


    }

    .nav-bar-section.scrolled {
        background-color: whitesmoke;
    }

    .nav-button {
        position: relative;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        padding: 10px 20px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 30px;
        margin-right: -30px;
    }

    button {
        position: relative;
        background-color: #FF8C00;
        padding: 5px 15px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 7px;
    }

    .navlink {
        font-weight: 500;
        margin-left: 1px;

        text-decoration: none;
        font-family: "Josefin sans";

    }

    .nav-item a button span {
        color: white;
    }



}

@media (min-width: 768px) and (max-width: 991px) {

    .nav-bar-section {

        height: 80px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    }

    .nav-bar-section .container {
        padding-top: 16px;

    }

    .nav-bar-section .logo-div {

        width: 230px;
        height: 150px;
        margin-left: -45px;
        margin-top: -51px;

        text-align: left;




    }

    .nav-bar-section .logo-div img {
        height: 95%;
        width: 70%;


    }

    .navbar-toggler {
        color: white !important;

        border: 3px solid white;
        margin-top: -60px;

    }

    .navbar-toggler-icon {
        background-color: white !important;


    }




    .nav-link {
        text-align: center;
        display: inline-block;
        margin-left: 10px;

        font-family: "Josefin sans";
        line-height: 18px;
        font-weight: 500;
        font-style: normal;

        font-size: 20px;
        margin-top: 15px;




    }

    .nav-bar-section.scrolled {
        background-color: whitesmoke;
    }

    .nav-button {
        position: relative;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        padding: 10px 20px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 30px;
        margin-right: -30px;
    }

    button {
        position: relative;
        background-color: #FF8C00;
        padding: 5px 15px;
        margin-top: -30px;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 7px;
    }

    .navlink {
        font-weight: 500;
        margin-left: 1px;

        text-decoration: none;
        font-family: "Josefin sans";

    }

    .nav-item a button span {
        color: white;
    }

    .navbar-collapse.show {
        background-color: white;
    }



}