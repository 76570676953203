.main-about-dib {
    padding-bottom: 40px;
}
.main-mission-div {
    padding-left: 320px;
    padding-right: 320px;
    
}
.mission-contents {
    background-color: white;
    border-radius: 10px;
    background-color: white;
    z-index: 3;


    
}

.mission-header {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    font-size: 45px;
    color: #212158;
    padding-top: 10px;
}

.mission-text {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 300;
    
}

.line-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-line{
    height: 90px;
    background-color: coral;
    width: 2px;
    border-radius: 100px;
   
}

.main-vision-div {
    padding-left: 320px;
    padding-right: 320px;
}
.vision-contents {
    background-color: white;
    border-radius: 10px;
  
    
}

.vision-header {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    font-size: 45px;
    color: #212158;
    padding-top: 10px;
}

.vision-text {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 300;
    
}

.line-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.values-header {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    font-size: 45px;
    color: #212158;
    padding-top: 10px;
}

.values-text {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 300;
    
}

.vision-icon-contents {
    padding-top: 30px;
}


.about-images {
    height: 100px;
    width: auto;
}

.vision-icon-name {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    padding-top: 10px;
}

.vision-icon-text {
    font-family: "Mulish", sans-serif;
    padding-bottom: 20px;
}


.imgback {
    width: 280px;
    height: 210px;
    position: absolute;
    z-index: -1;
    top: 700px;
    left: 30px;
}

.imgback2 {
    width: 360px;
    height: 220px;
    position: absolute;
    z-index: -1;
    top: 900px;
    right: 0px;
}



@media only screen and (max-width: 767px) { 


    .main-about-dib {
        padding-bottom: 40px;
    }
    .main-mission-div {
        padding-left: 10px;
        padding-right:10px;
    
        
    }
    .mission-contents {
        background-color: white;
        border-radius: 10px;
        background-color: white;
        z-index: 3;
    
    
        
    }
    
    .mission-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 50px;
        color: #212158;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .mission-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .vertical-line{
        height: 90px;
        background-color: coral;
        width: 2px;
        border-radius: 100px;
       
    }
    
    .main-vision-div {
        padding-left: 20px;
        padding-right: 20px;
    }
    .vision-contents {
        background-color: white;
        border-radius: 10px;
      
        
    }
    
    .vision-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 50px;
        color: #212158;
        padding-top: 10px;
    }
    
    .vision-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .values-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .values-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .vision-icon-contents {
        padding-top: 30px;
    }
    
    
    .about-images {
        height: 100px;
        width: auto;
    }
    
    .vision-icon-name {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        padding-top: 10px;
    }
    
    .vision-icon-text {
        font-family: "Mulish", sans-serif;
        padding-bottom: 20px;
    }
    

}



@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) { 


    .main-about-dib {
        padding-bottom: 40px;
    }
    .main-mission-div {
        padding-left: 100px;
        padding-right: 100px;
        
    }
    .mission-contents {
        background-color: white;
        border-radius: 10px;
        background-color: white;
        z-index: 3;
    
    
        
    }
    
    .mission-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .mission-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .vertical-line{
        height: 90px;
        background-color: coral;
        width: 2px;
        border-radius: 100px;
       
    }
    
    .main-vision-div {
        padding-left: 100px;
        padding-right: 100px;
    }
    .vision-contents {
        background-color: white;
        border-radius: 10px;
      
        
    }
    
    .vision-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .vision-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .values-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .values-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .vision-icon-contents {
        padding-top: 30px;
    }
    
    
    .about-images {
        height: 100px;
        width: auto;
    }
    
    .vision-icon-name {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        padding-top: 10px;
    }
    
    .vision-icon-text {
        font-family: "Mulish", sans-serif;
        padding-bottom: 20px;
    }
    



}



@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: portrait) { 


    .main-about-dib {
        padding-bottom: 40px;
    }
    .main-mission-div {
        padding-left: 100px;
        padding-right: 100px;
        
    }
    .mission-contents {
        background-color: white;
        border-radius: 10px;
        background-color: white;
        z-index: 3;
    
    
        
    }
    
    .mission-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .mission-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .vertical-line{
        height: 90px;
        background-color: coral;
        width: 2px;
        border-radius: 100px;
       
    }
    
    .main-vision-div {
        padding-left: 100px;
        padding-right: 100px;
    }
    .vision-contents {
        background-color: white;
        border-radius: 10px;
      
        
    }
    
    .vision-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .vision-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .values-header {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 45px;
        color: #212158;
        padding-top: 10px;
    }
    
    .values-text {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 300;
        
    }
    
    .vision-icon-contents {
        padding-top: 30px;
    }
    
    
    .about-images {
        height: 100px;
        width: auto;
    }
    
    .vision-icon-name {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        padding-top: 10px;
    }
    
    .vision-icon-text {
        font-family: "Mulish", sans-serif;
        padding-bottom: 20px;
    }
    



}


