.Social-team-main {
 padding-top: 40px;
}


.Social-team-main .Social-team-header {
    padding-bottom: 40px;
    
}

 .Social-team-small-text{
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.Social-team-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #212158;
}

.input-boxes {
    
    padding-top: 10px;
    
}

.enput{
    width: 100%;

    margin-bottom: 15px;
    border-radius: 15px;
    padding: 5px;
    
    color: #212158;
 
}


.input-message {
    
  
    margin-left: 220px;
    margin-right: 220px;
    background-color: yellow;

    
}

.input-message input {
    width: 100%;
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 5px;
    border: none;
    border: 2px solid #212158;
    outline: none;
  
    
}

.input-message input {
    height: 200px;
    
}
.input-message input:focus {
    border-color: #888; 
}


.contact-button-form {
    margin-top: 15px; 
    margin-bottom: 15px;
    border-radius: 6px;
    background: linear-gradient(to left, #e34212, rgba(239, 95, 52, 0.75));
    
  
}


.maps {
    margin-left: -90px;
    margin-right: -90px;

}



@media only screen and (max-width: 767px)

{

    .maps {
        margin-left: 0px;
        margin-right: 0px; 
    }

    .input-message {
    
        
        margin-left: 0px;
    margin-right: 0px;
       
        
    }
    
    .input-message input {
        width: 100%;
        border-radius: 5px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5px;
        border: none;
        height: 200px;
        
    }
    
    
    .input-message input:focus {
        border-color: #888; 
    }
    


}