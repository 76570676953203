.programs.header{
         
}

.programs-title {
    
        font-size: 18px;
        font-size: 1.2rem;
        color: #ef5f34;
        font-family: "Roboto Slab", serif;
}

.programs-text {
     
        font-size: 40px;
        font-size: 2.6666666667rem;
        margin: 0;
        position: relative;
        text-transform: capitalize;
        font-family: "Roboto Slab", serif;
        margin: 15px 0 20px;
        color: #212158;;
    
    
    
}

.programs-content {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
      
}


.programs-content .img-div {
        height: 290px;
        width: 350px;
        position: relative;
        ;
        
        
}


.programs-content .img-div .title-box {
        width: 100px;
        position: absolute;
        height: 30px;
        background-color: #ef5f34;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        margin-left: 10px;
        margin-top: 10px;
}


.programs-content .img-div .title-box-g {
        width: 100px;
        position: absolute;
        height: 30px;
        background-color: #5dc4b8;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        margin-left: 10px;
        margin-top: 10px;
}

.programs-content .img-div img {
        height: 100%;
        width: 100%;
        border-radius: 20px;
       
        
}



.programs-content .featured-title {
    color: #212158;
    font-family: "Roboto Slab", serif;
    font-size: 25px;      
    line-height: 35px;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
}


.programs-content .content-section {
        
        padding-left:17px ;
        padding-right: 17px;
        line-height: 22px;

}

.programs-content .featured-text {
        color: #4f555a;
    font-family: "Mulish", sans-serif;
    margin-bottom: 15px;
    font-weight: 500;
    
    
    
}

.learn-more-but {
        background-color: #FF8C00;
        
    padding: 5px 10px;
    margin-left: -220px ;
    margin-top: 9px;
    margin-bottom: 20px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    
}

@media only screen and (max-width: 767px) {
        .learn-more-but {
                background-color: #FF8C00;
                
            padding: 5px 10px;
            margin-left: -210px ;
            margin-top: 15px;
            
            margin-bottom: 20px;
            border: none;
            color: white;
            font-size: 23px;
            cursor: pointer;
            overflow: hidden;
            
        }

        .programs-content .featured-title {
                color: #212158;
                font-family: "Roboto Slab", serif;
                font-size: 29px;      
                line-height: 35px;
                margin-top: 0;
                font-weight: 600;
                margin-bottom: 15px;
                padding-left: 30px;
                padding-right: 30px;
            }

            .programs-content .content-section {
        
                padding-left:30px ;
                padding-right: 30px;
                line-height: 22px;
                font-size: 20px;
        
        }

}