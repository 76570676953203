.succestory-team-main {
    padding-top: 40px;
}


.succestory-team-main .counsel-team-header {
    padding-bottom: 40px;
    
}

 .succestory-team-small-text{
    font-size: 40px;
    font-family: "Alex Brush", serif;
    color: #ef5f34;
}

.succestory-team-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #222258;
    font-weight: 400;
    padding-bottom: 40px;
}



.team-box-success {
    display: flex;
    
    justify-content:center;
    align-items: center;
    padding: 20px;
 
}

/* Style for the image container */
.team-box-success .img-team-div2 {
   
    width: 900px; /* Adjust the width as needed */
    height: 400px;
    overflow: hidden;
    margin-left: 100px;
    
    
}

.team-box-success .img-team-div2 img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

/* Style for the team name */
.team-name2 {
    font-size: 35px;
    padding-top: 20px;
    color: #222258;
    font-family: "Playfair Display", serif;
}

/* Style for the team expertise */
.team-expertise2 {
    font-size: 22px;
    color: #ef5f34;
    padding-top: 2px;
    font-family: "Playfair Display", serif;
    
}

.content-sextion {

    height: 370px;
    max-width: 1000px;
    margin-left:20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Style for the team bio */
.team-bio2 {
    font-size: 19px;
    height: 500px;
    color: #555;
    text-align: left;
    margin-top: 20px;
    color: #4f555a;
 
    
  
    font-family: "Mulish", sans-serif;
    
    
}

.content-info {

    height: 800px;
    width: 950px;
}

@media only screen and (max-width: 767px) {
    .team-box-success {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .team-box-success .img-team-div2 {
        width: 100%;
        height: 350px;
        margin-left: 0px;
    }

    .team-name2 {
        font-size: 28px;
       
    }

    .team-expertise2 {
        font-size: 18px;
        padding-top: 2px;
    }

    .content-sextion {
        height: 600px;
        max-width: 100%;
        margin-left: 0;
    }

    .team-bio2 {
        font-size: 16px;
        height: auto;
        margin-top: 10px;
        line-height: 20px;
        
    }

    .content-info {
        width: 100%;
        max-width: 100%;
    }
}