.Sheader-main {
    position: relative;
    margin-top: 80px;
    background-image: url(https://www.setopati.com/uploads/editor/old_cms_images/FEATURE/chetana/Chetana%202022%20all/susil%20%282%29.jpg);
    background-size: cover;
    background-position: 30% 70%;
    background-repeat: no-repeat;
    height: 400px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.Sheader-main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.Sheader-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; /* Add relative positioning to the image */
    z-index: 0;
}

.Scontents .S-title {
    font-family: 'Playfair Display', serif;
    font-size: 50px;
    position: relative; /* Add relative positioning to the title */
    z-index: 2; /* Increase the z-index to ensure the title is above the overlay */
    color: white;
}

.Scontents .S-sub {
    background-color: #FF8C00;
    position: relative;
    z-index: 2;
    border-radius: 7px;
    padding: 1px 6px ;
    border: none;
    
}

.S-sub span {
    color: white;
    font-family: "Poppins", serif;

}