.main-div-cta {
    margin-top: 200px;
    margin-left: 20px;
    margin-right: 20px;
    background: url('https://wpocean.com/wp/charitio/wp-content/uploads/2021/11/cta-shape-min.png') center/cover no-repeat;
    position: relative;
    border-radius: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  

.main-div-cta .container-fluid {
    background: linear-gradient(to left, rgba(239, 95, 52, 0.75), #ed4918);
    position: relative;
    z-index: 1;
    border-radius: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
   
}

.cta-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}


.cta-title {
    
    width: 90%;
    padding-left: 70px;

}

.cta-title h2 {
    font-size: 45px;
    color: #FFFFFF;
    font-family: "Yeseva One", cursive;
    line-height: 65px;
}

.cta-button {
    background-color: #FFFFFF;
    color: #062265;
    border-radius: 4px;
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    
}

.cta-button a {
    
    font-family: "Roboto Slab", serif;
   
 
    font-size: 20px;

}


.cta-img {
    position: absolute;
    top: -189px;
    right: 40px;
}



@media only screen and (max-width: 767px) {

   .cta-img {
    display: none;
   }
    
   .main-div-cta {
    margin-top: 60px;
    margin-left: 20px;
    margin-right: 20px;
}


.main-div-cta .container-fluid {
    background: linear-gradient(to left, rgba(239, 95, 52, 0.75), #ed4918);
    position: relative;
    z-index: 1;
    border-radius: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
   
}

.cta-title {
    
    width: 100%;
    padding-left: 5px;

}

.cta-title h2 {
    font-size: 35px;
    color: #FFFFFF;
    font-family: "Roboto Slab", serif;
    line-height: 42px;
}

.cta-button {
    background-color: #FFFFFF;
    color: #062265;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    
}

.cta-button a {
    
    font-family: "Roboto Slab", serif;
   
 
    font-size: 20px;

}



}