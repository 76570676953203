.upcomming-main-div {
    padding-top: 40px;
}

.row {
    padding-top: 25px;
}


.upcomming-main-div .upcomming-header {
    padding-bottom: 40px;
    
}

.upcomming-main-div .upcomming-header .upcomming-small-text {
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.upcomming-main-div .upcomming-header .upcomming-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #212158;
}


.past-contents {
    margin-top: 30px;
}

.img-past-div  {
    height: 320px;
    width: 100%;
}
.img-past-div img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}


.upcoming-event-content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  
}


.upcoming-event-content .img-div {
    height: 300px;
    width: 380px;
    position: relative;

    
    
}


.upcoming-event-content .img-div .title-box {
    width: 60px;
    position: absolute;
    height: 70px;
    background-color: #276b63;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-left: 30px;
    margin-top: 20px;
}

.upcoming-event-content .img-div .title-box span {
    padding-bottom: 5px;
    font-size: 17px;margin-top: -10px;
}



.upcoming-event-content .img-div img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
   
    
}

.internal-contents {
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    
    padding-left: 10px;
    padding-top: 5px;
   
}



.upcoming-event-content .upcoming-event-title {
    color: #212158;
    font-family: "Roboto Slab", serif;
    font-size: 25px;      
    line-height: 35px;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 15px;
    padding-left: 17px; 
    padding-right: 12px;
    
}

.upcoming-event-content .upcoming-event-content-section {
    
    padding-right: 17px;
    line-height: 22px;

    padding-left: 17px;
}


.upcoming-event-content .featured-text {
    color: #4f555a;
    font-family: "Mulish", sans-serif;
    margin-bottom: 15px;
    font-weight: 500;
    padding-left: 0;
 
}