.motiveintro-main-div {
    padding-top: 40px;
}


.motiveintro-main-div .motiveintro-header {
    padding-bottom: 40px;
    
}

.motiveintro-main-div .motiveintro-header .motiveintro-small-text {
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.motiveintro-main-div .motiveintro-header .motiveintro-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #212158;
}

.img-motiveintro {
    width: 490px;
    height: 420px;
    
}

.img-motiveintro  img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    
}

.motiveintro-contents {
    color: #4f555a;
        font-family: "Mulish", sans-serif;
        
        font-size: 19px;
}


@media only screen and (max-width: 767px)

{
    .img-motiveintro {
        width: 400px;
        height: 380px;
        
    }


}