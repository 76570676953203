.Cg-test {
    padding-top: 60px;
}


.Cg-test .Cgtest-header {
    padding-bottom: 40px;
    
}

.Cg-test .Cgtest-header .Cgtest-small-text {
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.Cg-test .Cgtest-header .Cgtest-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #212158;
}



.team-box1 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 20px;
    
}

/* Style for the image container */
.team-box1 .img-team-div1 {
   
    width: 200px; /* Adjust the width as needed */
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
  
    
}

.team-box1 .img-team-div1 img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

/* Style for the team name */
.team-name1 {
    font-size: 28px;
    padding-top: 10px;
    color: #212158;
    font-family: "Playfair Display", serif;
    
}

/* Style for the team expertise */
.team-expertise1 {
    font-size: 18px;
    color: #ef5f34;
    padding-top: 1px;
    font-family: "Playfair Display", serif;
    
}

/* Style for the team bio */
.team-bio1 {
    font-size: 14px;
    color: #555;
    text-align: left;
    margin-top: 10px;
    color: #4f555a;
  
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    padding-left: 60px;
    padding-right:40px ;
    
    
}


.info-test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 100px;
 
    width: 500px;
}



@media only screen and (max-width: 767px) { 


.team-box1 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 20px;
}

/* Style for the image container */
.team-box1 .img-team-div1 {
   
    width: 200px; /* Adjust the width as needed */
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
  
    
}

.team-box1 .img-team-div1 img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

/* Style for the team name */
.team-name1 {
    font-size: 28px;
    padding-top: 10px;
    color: #212158;
    font-family: "Playfair Display", serif;
    
}

/* Style for the team expertise */
.team-expertise1 {
    font-size: 18px;
    color: #ef5f34;
    padding-top: 1px;
    font-family: "Playfair Display", serif;
    
}

/* Style for the team bio */
.team-bio1 {
    font-size: 14px;
    color: #555;
    text-align: left;
    margin-top: 10px;
    color: #4f555a;
  
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    padding-left: 0px;
    padding-right:0px ;
    
    
}


.info-test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
 
    width: auto;
}






}