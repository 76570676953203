.counsel-main-div {
    padding-top: 40px;
}


.counsel-main-div .counsel-header {
    padding-bottom: 70px;
    
}

.counsel-main-div .counsel-header .counsel-small-text {
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.counsel-main-div .counsel-header .counsel-title {
    font-size: 40px;
    font-family:"Yeseva One", cursive ;
    color: #212158;
    padding-top: 0px;
}

.img-counsel {
    width: 470px;
    height: 420px;
    
}

.img-counsel  img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    
}

.counsel-contents {
    color: #4f555a;
        font-family: "Mulish", sans-serif;
        
        font-size: 19px;
}

.design1 {

    left: 50%;
    position: absolute;
    top: 91%;
    z-index: -1;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}



@media only screen and (max-width: 767px)

{


.img-counsel {
    width: 400px;
    height: 380px;
    
}


}