

.title-main-div {
    padding-top: 30px;
    padding-left: 80px;
    
}

.title-small {
    color: #fc6539;

    display: inline-block;
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large {
    font-family: "Yeseva One", cursive;
    font-size: 60px;
}

.span-about {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left {
  
    height: 500px;
    width: 100%;
    
}


.img1 {
    padding-top: 60px;

    padding-left: 80px;
    width: 340px;
    height: 420px;
}
.img2 {
    padding-left: 20px;
    padding-top: 60px;
    width: 380px;
    height: 420px;
}

.aboutcontent-right {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:-30px 
}

.aboutright-top {
    display: flex;
}

.linee {
    height: 73px;
    margin-top: 12px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 30px;
}

.abouttextfirst {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 36px;
    line-height: 26px;
}
.abouttextsecond {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 36px;
}

.listabout {
    list-style: none;
    padding-top: 10px;
}

.listabout li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn {
    margin-left: 32px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span {
    font-size: 18px;
    font-weight: 500;
}

.hline {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp  {
    display: flex;
}



@media only screen and (max-width: 767px)

{


.title-main-div {
    padding-top: 0px;
    padding-left: 0px;
    
}

.title-small {
    color: #fc6539;

    display: inline-block;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large {
    font-family: "Yeseva One", cursive;
    font-size: 40px;
   
    
}

.span-about {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left {
  
    height: 400px;
    width: 100%;
    background-color: rebeccapurple;
    
}


.img1 {
    padding-top: 0px;
    padding-left: 0px;
    display: none;
}
.img2 {
    padding-left: 0px;
    padding-top: 0px;
    height: 300px;
    width: 100%;
    height: 100%;
}

.aboutcontent-right {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:0px 
}

.aboutright-top {
    display: flex;
}

.linee {
    height: 80px;
    margin-top: 12px;
    width: 12px;
    background-color: #FC6539;
    
    
}

.abouttextmain {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 0px;
    text-align: center;
}

.abouttextfirst {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 26px;
}
.abouttextsecond {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
}

.listabout {
    list-style: none;
    padding-top: 10px;
    
    padding-left: 20px;
}

.listabout li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn {
    margin-left: 20px;
    border-radius:30px;
    padding-top: 15px ;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span {
    font-size: 18px;
    font-weight: 500;

}

.hline {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp  {
    display: flex;
}





}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {

        .title-main-div {
            padding-top: 20px;
            padding-left: 0px;
            
        }
        
        .title-small {
            color: #fc6539;
            display: inline-block;
            font-size: 25px;
            font-family: "Roboto Slab", serif;
            color: #ef5f34;
            line-height: 2;
            position: relative;
        }
        .title-large {
            font-family: "Yeseva One", cursive;
            font-size: 50px;
            padding-bottom: 10px;
            
        }
        .span-about {
            background: #fea444;
            background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
        .aboutcontent-left {
          
            height: 400px;
            width: 100%;
       
            
        }
        
        
        .img1 {
            padding-top: 0px;
            padding-left: 0px;
            display: none;
        }
        .img2 {
            padding-left: 0px;
            padding-top: 0px;
            
            width: 100%;
            height: 100%;
        }
        
        .aboutcontent-right {
          
            height: 500px;
            width: 100%;
            margin-top: 0px;
            margin-left:0px;

    }
        
        .aboutright-top {
            display: flex;
        }
        
        .linee {
            height: 80px;
            margin-top: 12px;
            width: 12px;
            background-color: #FC6539;
            
            
        }
        
        .abouttextmain {
            font-size: 22px;
            font-family: "Yeseva One", cursive;
            padding-left: 0px;
            text-align: left;
            padding-left: 15px;
        }
        
        .abouttextfirst {
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            color: #595959;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 26px;
        }
        .abouttextsecond {
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            color: #595959;
            line-height: 26px;
            padding-left: 20px;
            padding-right: 20px;
        }
        
        .listabout {
            list-style: none;
            padding-top: 10px;
            
            padding-left: 20px;
        }
        
        .listabout li {
            font-size: 15px;
            font-family: "Yeseva One", cursive;
            color: #001d23;
            font-weight: 500;
            letter-spacing: 1px;
        }
        
        .about-buttonn {
            margin-left: 20px;
            border-radius:30px;
            padding-top: 15px ;
            padding-bottom: 15px;
            padding-left: 20px;
            padding-right: 20px;
            background: #FEA444;
            background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        }
        
        .butt-span {
            font-size: 18px;
            font-weight: 500;
        
        }
        
        .hline {
            background-color: #fc6539;
            height: 5px;
            width: 40px;
            margin-right: 20px;
            margin-top: 23px;
        }
        
        .toppp  {
            display: flex;
        }
        
        
        

}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

    .title-main-div {
        padding-top: 20px;
        padding-left: 0px;
        
    }
    
    .title-small {
        color: #fc6539;
        display: inline-block;
        font-size: 25px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        position: relative;
    }
    .title-large {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
        padding-bottom: 10px;
    }
    .span-about {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .aboutcontent-left {
      
        height: 400px;
        width: 100%;
   
        
    }
    
    
    .img1 {
        padding-top: 0px;
        padding-left: 0px;
        display: none;
        
    }
    .img2 {
        padding-left: 0px;
        padding-top: 0px;
        
        width: 80%;
        height: 100%;
    }
    
    .aboutcontent-right {
      
        height: 500px;
        width: 135%;
        margin-top: 0px;
        margin-left:-80px;

}
    
    .aboutright-top {
        display: flex;
    }
    
    .linee {
        height: 80px;
        margin-top: 12px;
        width: 12px;
        background-color: #FC6539;
        
        
    }
    
    .abouttextmain {
        font-size: 25px;
        font-family: "Yeseva One", cursive;
        padding-left: 0px;
        text-align: left;
        padding-left: 15px;
    }
    
    .abouttextfirst {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
    }
    .abouttextsecond {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 26px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout {
        list-style: none;
        padding-top: 10px;
        
        padding-left: 20px;
    }
    
    .listabout li {
        font-size: 15px;
        font-family: "Yeseva One", cursive;
        color: #001d23;
        font-weight: 500;
        letter-spacing: 1px;
    }
    
    .about-buttonn {
        margin-left: 20px;
        border-radius:30px;
        padding-top: 15px ;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span {
        font-size: 18px;
        font-weight: 500;
    
    }
    
    .hline {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 23px;
    }
    
    .toppp  {
        display: flex;
    }
    
    
    

}




@media only screen and (min-device-width: 760px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait)  {
    .title-main-div {
        padding-top: 20px;
        padding-left: 0px;
        
    }
    
    .title-small {
        color: #fc6539;
        display: inline-block;
        font-size: 25px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        position: relative;
    }
    .title-large {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
        padding-bottom: 10px;
        
    }
    .span-about {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .aboutcontent-left {
      
        height: 400px;
        width: 100%;
   
        
    }
    
    
    .img1 {
        padding-top: 0px;
        padding-left: 0px;
        display: none;
    }
    .img2 {
        padding-left: 90px;
        padding-top: 10px;
        padding-right: 90px;
        
        
        width: 100%;
        height: 100%;
    }
    
    .aboutcontent-right {
      
        height: 500px;
        width: 100%;
        margin-top: 40px;
        margin-left:0px;

}
    
    .aboutright-top {
        display: flex;
    }
    
    .linee {
        height: 80px;
        margin-top: 12px;
        width: 12px;
        background-color: #FC6539;
        
        
    }
    
    .abouttextmain {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 0px;
        text-align: left;
        padding-left: 15px;
    }
    
    .abouttextfirst {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
    }
    .abouttextsecond {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 26px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout {
        list-style: none;
        padding-top: 10px;
        
        padding-left: 20px;
    }
    
    .listabout li {
        font-size: 15px;
        font-family: "Yeseva One", cursive;
        color: #001d23;
        font-weight: 500;
        letter-spacing: 1px;
    }
    
    .about-buttonn {
        margin-left: 20px;
        border-radius:30px;
        padding-top: 15px ;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span {
        font-size: 18px;
        font-weight: 500;
    
    }
    
    .hline {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 23px;
    }
    
    .toppp  {
        display: flex;
    }
    
    


}

@media only screen and (min-device-width: 760px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait)  {
    .title-main-div {
        padding-top: 20px;
        padding-left: 0px;
        
    }
    
    .title-small {
        color: #fc6539;
        display: inline-block;
        font-size: 25px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        position: relative;
    }
    .title-large {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
        padding-bottom: 10px;
        
    }
    .span-about {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .aboutcontent-left {
      
        height: 400px;
        width: 100%;
   
        
    }
    
    
    .img1 {
        padding-top: 0px;
        padding-left: 0px;
        display: none;
    }
    .img2 {
        padding-left: 90px;
        padding-top: 10px;
        padding-right: 90px;
        
        
        width: 100%;
        height: 100%;
    }
    
    .aboutcontent-right {
      
        height: 500px;
        width: 100%;
        margin-top: 40px;
        margin-left:0px;

}
    
    .aboutright-top {
        display: flex;
    }
    
    .linee {
        height: 80px;
        margin-top: 12px;
        width: 12px;
        background-color: #FC6539;
        
        
    }
    
    .abouttextmain {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 0px;
        text-align: left;
        padding-left: 15px;
    }
    
    .abouttextfirst {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
    }
    .abouttextsecond {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 26px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout {
        list-style: none;
        padding-top: 10px;
        
        padding-left: 20px;
    }
    
    .listabout li {
        font-size: 15px;
        font-family: "Yeseva One", cursive;
        color: #001d23;
        font-weight: 500;
        letter-spacing: 1px;
    }
    
    .about-buttonn {
        margin-left: 20px;
        border-radius:30px;
        padding-top: 15px ;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span {
        font-size: 18px;
        font-weight: 500;
    
    }
    
    .hline {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 23px;
    }
    
    .toppp  {
        display: flex;
    }
    
    


}



@media (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {


    .title-main-div {
        padding-top: 20px;
        padding-left: 0px;
        
    }
    
    .title-small {
        color: #fc6539;
        display: inline-block;
        font-size: 25px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        position: relative;
    }
    .title-large {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
        padding-bottom: 10px;
        
    }
    .span-about {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .aboutcontent-left {
      
        height: 400px;
        width: 100%;
   
        
    }
    
    
    .img1 {
        padding-top: 0px;
        padding-left: 0px;
        display: none;
    }
    .img2 {
        padding-left: 90px;
        padding-top: 10px;
        padding-right: 90px;
        
        
        width: 100%;
        height: 100%;
    }
    
    .aboutcontent-right {
      
        height: 500px;
        width: 100%;
        margin-top: 40px;
        margin-left:0px;

}
    
    .aboutright-top {
        display: flex;
    }
    
    .linee {
        height: 80px;
        margin-top: 12px;
        width: 12px;
        background-color: #FC6539;
        
        
    }
    
    .abouttextmain {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 0px;
        text-align: left;
        padding-left: 15px;
    }
    
    .abouttextfirst {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
    }
    .abouttextsecond {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 26px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout {
        list-style: none;
        padding-top: 10px;
        
        padding-left: 20px;
    }
    
    .listabout li {
        font-size: 15px;
        font-family: "Yeseva One", cursive;
        color: #001d23;
        font-weight: 500;
        letter-spacing: 1px;
    }
    
    .about-buttonn {
        margin-left: 20px;
        border-radius:30px;
        padding-top: 15px ;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span {
        font-size: 18px;
        font-weight: 500;
    
    }
    
    .hline {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 23px;
    }
    
    .toppp  {
        display: flex;
    }
    
    



}

