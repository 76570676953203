@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');


.past-main-div {
    padding-top: 40px;
}


.past-main-div .container .row {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px;
    
}

.past-events-contents {
    padding-top: 30px;

}


.img-div-past {
    width: 100%;
    height: 280px;
    margin-top: 50px;

}

.img-div-past img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}


.event-date {
    color: #107569;
    font-family: "Mulish", sans-serif;
    padding-top: 10px;
}

.event-title {
    text-decoration: none;
    color: #212158;
    font-size: 25px;
    font-family: "Roboto Slab", serif;

}

.event-text {
    color: #4f555a;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    padding-top: 15px;
}


.read-more {
    text-decoration: none;;
    color: #212158;
    font-size: 20px;
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    cursor: pointer;
}

.last-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.shape-event {
    
   
    background: rgba(93, 196, 184, 0.6);
    width: 10px;
    height: 180px;
    border-radius: 10px 0 0 10px;
   
}

@media screen and (max-width: 768px) {

    .past-main-div .container .row {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 50px;
        
    }

    .shape-event {
        display: none;
    }

    .past-main-div .container .row {
        padding-left: 100px;
        padding-right: 100px;
        margin-bottom: 50px;
        
    }
    
    .past-events-contents {
        padding-top: 30px;
    
    }
    
.img-div-past {
    width: 100%;
    height: 280px;
    margin-top: 50px;
}
    

}