@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap');


.pasttheader-main-div {
    position: relative;
    padding-top: 60px;
}


.pasttheader-main-div .pastt-header {
    padding-bottom: 40px;
    
}

.pastt-small-text {
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

 .pastt-title {
    font-size: 60px;
    font-family:"Yeseva One", cursive ;
    color: #212158;
    font-weight: 300;
    margin-top: 39px;
}

.design {

    left: 50%;
    position: absolute;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}



@media only screen and (max-width: 767px) 
{
    pasttheader-main-div {
        position: relative;
     
    }
    
    
    .pasttheader-main-div .pastt-header {
        padding-bottom: 40px;
        
        padding-top: 80px;
       

        
    }
    
    .pastt-small-text {
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        
    }
    
     .pastt-title {
        font-size: 50px;
        font-family:"Yeseva One", cursive ;
        color: #212158;
        font-weight: 300;
        margin-top: 39px;
    }
    
    .design {
    
        left: 50%;
        position: absolute;
        top: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        
    }
    
    
}