.footer-section {
    margin-top: 40px;
    background: #29395b;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-section .title {
    
        font-size: 30px;
        color: #E7E7E7;
        line-height: 35px;
        text-align: left;
        font-family: "Poppins", serif;
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
    
}

.footer-section .text {
    
        font-size: 16px;
        color: #E7E7E7;
        line-height: 27px;
        text-align: left;
        font-family: "Mulish", serif;
        font-weight: 300;
        font-style: normal;
    
}

.footer-section ul {
    list-style: none;
}

.footer-section .colom-2 {
    
        font-size: 30px;
        color: #E7E7E7;
        line-height: 32px;
        text-align: left;
        font-family: 'Roboto Slab',serif;
        font-weight: 300;
        font-style: normal;
    
}


.footer-section .location {
    list-style: none;
    padding: 0;
    outline: none !important;
    box-sizing: border-box;
}

.footer-section .location li {
    color: #E7E7E7;
    position: relative;
    margin-bottom: 12px;
    
}

.footer-section .location i {
    margin-right: 10px ;
}




.footer-section .timing {
    
        font-size: 16px;
        color: #E7E7E7;
        line-height: 27px;
        text-align: left;
        font-family: "Mulish", serif;
        font-weight: 200;
        font-style: normal;
    }


    .footer-section .link-title {
        
            font-size: 30px;
            color: #E7E7E7;
            line-height: 32px;
            text-align: left;
            font-family:'Roboto Slab',serif;
            font-weight: 300;
            font-style: normal;
        }
    
        .footer-section .Menu-lists {
            padding: 0;
            list-style: none;
            outline: none!important;
            box-sizing: border-box;
        }

        .footer-section .Menu-lists li {
            color: #E7E7E7;
            position: relative;
            margin-bottom: 12px;
            font-family: "Mulish", serif;
            font-weight: 300;
            cursor: pointer;
            
        }

        .footer-section .updated {
            font-size: 30px;
            color: #E7E7E7;
            line-height: 32px;
            text-align: left;
            font-family:'Roboto Slab',serif;
            font-weight: 300;
            font-style: normal;
        }

            
        .footer-section .email-form {
            width: 75%;
            height: 40px;
            background-color: #29395b;
            border: 1px solid white;
        }
        .footer-section .email-form::placeholder {
                background-color: #29395b;
                padding-left: 10px;
                color: white;
            }

            .footer-section .submit-butan {
                width: auto;
                margin-top: 20px;
                margin-right: auto;
                margin-bottom: 20px;
                margin-left: auto;
                padding-top: 8px;
                padding-right: 30px;
                padding-bottom: 8px;
                padding-left: 30px;
                background-color: #c59d5f;
                color: #ffffff;
                border-top: 0px;
                border-right: 0px;
                border-bottom: 0px;
                border-left: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
            
            }


        .footer-section .social-section i {
            color: black;
            margin-right: 10px;
        }


        .bottom-footer {
            background-color: #192847;;
        }


        .copyright-section {
            font-family: "Mulish", sans-serif;
            color: #E7E7E7;
            padding-top: 3px;
            padding-bottom: 3px;
        }