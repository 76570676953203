@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');


.Counsel-team-main {
    padding-top: 40px;
}


.Counsel-team-main .counsel-team-header {
    padding-bottom: 40px;
    
}

 .counsel-team-small-text{
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
}

.counsel-team-title {
    font-size: 40px;
    font-family:"Yeseva One", serif ;
    color: #212158;
    font-weight: 300;
}



.team-box {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 20px;
}

/* Style for the image container */
.team-box .img-team-div {
   
    width: 420px; /* Adjust the width as needed */
    height: auto;
   
    display: flex;
    justify-content: center;
    
    
    
    
}

.team-box .img-team-div img {
    
    width: 200px;
    height: auto;
    border-radius: 100px;
}

/* Style for the team name */
.team-name {
    font-size: 28px;
    padding-top: 10px;
    font-weight: 300;
    color: #212158;
    font-family: "Roboto Slab", serif;
}

/* Style for the team expertise */
.team-expertise {
    font-size: 18px;
    color: #ef5f34;
    padding-top: 1px;
    font-weight: 300;
    font-family: "Roboto Slab", serif;
    
}

/* Style for the team bio */
.team-bio {
    font-size: 14px;
    color: #555;
    text-align: left;
    margin-top: 10px;
    color: #4f555a;
  
    font-family: "Mulish", sans-serif;
    
    
}
