 
    .donyouth {
        padding-bottom: 250px;
    }


.aboutdon-main {
    padding-bottom: 100px;
}
.title-main-div-don {
    padding-top: 30px;
    padding-left: 80px;
    
}

.title-small-don {
    color: #fc6539;

    display: inline-block;
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-don {
    font-family: "Yeseva One", cursive;
    font-size: 60px;
}

.span-about-don {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-don {
  
    height: 500px;
    width: 100%;
    
}


.img1-don {
    padding-top: 50px;
    padding-left: 80px;
    width: 90%;
    height: 550px;
}
.img2-don {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-don {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:-30px 
}

.aboutright-top-don {
    display: flex;
}

.linee-don {
    height: 73px;
    margin-top: 0px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-don {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 30px;
}

.abouttextfirst-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 36px;
    line-height: 26px;
}
.abouttextsecond-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 36px;
}

.listabout-don {
    list-style: none;
    padding-top: 10px;
}

.listabout-don li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-don {
    margin-left: 32px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span-don {
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-don {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-don  {
    display: flex;
}

/* 

.aboutyouth-main {
    padding-bottom: 80px;
}
.title-main-div-youth {
    padding-top: 30px;
    padding-left: 80px;
 

 
    
}
.title-large-youth {
    text-align: right;
}


.title-small-youth {
    color: #fc6539;
    
    
    font-size: 18px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-youth {
    font-family: "Yeseva One", cursive;
    font-size: 60px;
}

.span-about-youth {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-youth {
  
    height: 500px;
    width: 100%;
    
}


.img1-youth {
    padding-top: 50px;
    padding-left: 80px;
    width: 90%;
    height: 550px;
}
.img2-youth {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-youth {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:-30px 
}

.aboutright-top-youth {
    display: flex;
}

.linee-youth {
    height: 73px;
    margin-top: 0px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-youth {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 30px;
}

.abouttextfirst-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 36px;
    line-height: 26px;
}
.abouttextsecond-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 36px;
}

.listabout-youth {
    list-style: none;
    padding-top: 10px;
}

.listabout-youth li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-youth {
    margin-left: 32px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: not-allowed;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span-youth{
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-youth {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-youth  {
    display: flex;
    justify-content: flex-end;
    
} */


@media only screen and (max-width: 500px)  {

   
.aboutdon-main {
    padding-bottom: 300px;
    padding-top: 00px;
}
.title-main-div-don {
    padding-top: 30px;
    padding-left: 0px;
    
}

.title-small-don {
    color: #fc6539;

    display: inline-block;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-don {
    font-family: "Yeseva One", cursive;
    font-size: 50px;
}

.span-about-don {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-don {
  
    height: 500px;
    width: 100%;
    
}


.img1-don {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 550px;
}
.img2-don {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-don {
  
    height: 500px;
    width: 100%;
    margin-top: 65px;
    margin-left:0px 
}

.aboutright-top-don {
    display: flex;
}

.linee-don {
    height: 120px;
    margin-top: 0px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-don {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 20px;

}

.abouttextfirst-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 26px;
}
.abouttextsecond-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
}

.listabout-don {
    list-style: none;
    padding-top: 10px;
}

.listabout-don li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-don {
    margin-left: 0px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    
}

.butt-span-don {
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-don {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-don  {
    display: flex;
}


/* 
.aboutyouth-main {
    padding-bottom: 630px;
    padding-top: 400px;

}
.title-main-div-youth {
    padding-top: 30px;
    padding-left: 0px;
 

 
    
}
.title-large-youth {
    text-align: left;
}


.title-small-youth {
    color: #fc6539;
    
    
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-youth {
    font-family: "Yeseva One", cursive;
    font-size: 50px;
}

.span-about-youth {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-youth {
  
    height: 500px;
    width: 100%;
    
}


.img1-youth {
    margin-top: -600px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 550px;
}
.img2-youth {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-youth {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:-30px 
}

.aboutright-top-youth {
    display: flex;
}

.linee-youth {
    height: 73px;
    margin-top: 0px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-youth {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    margin-left: 0px;
    padding-top: 500px;
}

.abouttextfirst-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 40px;
    
    line-height: 26px;
}
.abouttextsecond-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 26px;
    padding-left: 36px;
}

.listabout-youth {
    list-style: none;
    padding-top: 10px;
}

.listabout-youth li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-youth {
    
    margin-left: 40px;
    margin-right: -20px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span-youth{
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-youth {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-youth  {
    display: flex;
    justify-content: flex-end;
    
} */

}


/* for ipad pro  */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) { 

    
    
    .donyouth {
        padding-bottom: 250px;
    }
    
    
    .aboutdon-main {
        padding-bottom: 80px;
        padding-top: 0px;
    }
    .title-main-div-don {
        padding-top: 30px;
        padding-left: 0px;
        
    }
    
    .title-small-don {
        color: #fc6539;
    
        display: inline-block;
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-don {
        font-family: "Yeseva One", cursive;
        font-size: 60px;
        padding-bottom: 15px;
    }
    
    .span-about-don {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-don {
      
        height: 500px;
        width: 100%;
       
        
    }
    
    
    .img1-don {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-don {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-don {
      
        height: 500px;
        width: 100%;
        margin-top: 0px;
        margin-left:0px 
    }
    
    .aboutright-top-don {
        display: flex;
    }
    
    .linee-don {
        height: 120px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-don {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 20px;
        
    
    }
    
    .abouttextfirst-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 22px;
    }
    .abouttextsecond-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout-don {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-don li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-don {
        margin-left: 0px;
    
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-don {
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-don {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-don  {
        display: flex;
    }
    
    
/*     
    .aboutyouth-main {
        padding-bottom: 220px;
        padding-top: 100px;
    
    }
    .title-main-div-youth {
        padding-top: 30px;
        padding-left: 0px;
     
    
     
        
    }
    .title-large-youth {
        text-align: left;
    }
    
    
    .title-small-youth {
        color: #fc6539;
        
        
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-youth {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
    }
    
    .span-about-youth {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-youth {
      
        height: 500px;
        width: 100%;
        
        
    }
    
    
    .img1-youth {
        margin-top: 00px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-youth {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-youth {
      
        height: 500px;
        width: 100%;
        margin-top: 20px;
        margin-left:-30px 
    }
    
    .aboutright-top-youth {
        display: flex;
    }
    
    .linee-youth {
        height: 73px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-youth {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        margin-left: 0px;
        padding-top: 00px;
    }
    
    .abouttextfirst-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 40px;
        
        line-height: 22px;
    }
    .abouttextsecond-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 36px;
    }
    
    .listabout-youth {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-youth li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-youth {
        
        margin-left: 40px;
        margin-right: -20px;
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-youth{
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-youth {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-youth  {
        display: flex;
        justify-content: flex-end;
        
    } */
    



}




@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) { 


    
    .aboutdon-main {
        padding-bottom: 80px;
        padding-top: 0px;
    }
    .title-main-div-don {
        padding-top: 30px;
        padding-left: 0px;
        
    }
    
    .title-small-don {
        color: #fc6539;
    
        display: inline-block;
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-don {
        font-family: "Yeseva One", cursive;
        font-size: 60px;
        padding-bottom: 15px;
    }
    
    .span-about-don {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-don {
      
        height: 500px;
        width: 100%;
       
        
    }
    
    
    .img1-don {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-don {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-don {
      
        height: 500px;
        width: 100%;
        margin-top: 0px;
        margin-left:0px 
    }
    
    .aboutright-top-don {
        display: flex;
    }
    
    .linee-don {
        height: 120px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-don {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 20px;
        
    
    }
    
    .abouttextfirst-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 22px;
    }
    .abouttextsecond-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout-don {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-don li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-don {
        margin-left: 0px;
    
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-don {
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-don {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-don  {
        display: flex;
    }
    
    
/*     
    .aboutyouth-main {
        padding-bottom: 220px;
        padding-top: 100px;
    
    }
    .title-main-div-youth {
        padding-top: 30px;
        padding-left: 0px;
     
    
     
        
    }
    .title-large-youth {
        text-align: left;
    }
    
    
    .title-small-youth {
        color: #fc6539;
        
        
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-youth {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
    }
    
    .span-about-youth {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-youth {
      
        height: 500px;
        width: 100%;
        
        
    }
    
    
    .img1-youth {
        margin-top: 00px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-youth {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-youth {
      
        height: 500px;
        width: 100%;
        margin-top: 20px;
        margin-left:-30px 
    }
    
    .aboutright-top-youth {
        display: flex;
    }
    
    .linee-youth {
        height: 73px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-youth {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        margin-left: 0px;
        padding-top: 00px;
    }
    
    .abouttextfirst-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 40px;
        
        line-height: 22px;
    }
    .abouttextsecond-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 36px;
    }
    
    .listabout-youth {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-youth li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-youth {
        
        margin-left: 40px;
        margin-right: -20px;
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-youth{
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-youth {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-youth  {
        display: flex;
        justify-content: flex-end;
        
    }
     */



}


@media only screen and (max-device-width: 375px)

{

    
       
    .donyouth {
        padding-bottom: 540px;
    }
    
.aboutdon-main {
    padding-bottom: 80px;
    padding-top: 0px;
}
.title-main-div-don {
    padding-top: 30px;
    padding-left: 0px;
    
}

.title-small-don {
    color: #fc6539;

    display: inline-block;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-don {
    font-family: "Yeseva One", cursive;
    font-size: 45px;
}

.span-about-don {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-don {
  
    height: 400px;
    width: 100%;
    
}


.img1-don {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    
    width: 100%;
    height: 400px;
}
.img2-don {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-don {
  
    height: 500px;
    width: 100%;
    margin-top: 10px;
    margin-left:0px 
}

.aboutright-top-don {
    display: flex;
}

.linee-don {
    height: 120px;
    margin-top: 4px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-don {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    padding-left: 20px;


}

.abouttextfirst-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 24px;
}
.abouttextsecond-don {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
}

.listabout-don {
    list-style: none;
    padding-top: 10px;
}

.listabout-don li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-don {
    margin-top: -20px;
    margin-left: 0px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span-don {
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-don {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-don  {
    display: flex;
}

/* 

.aboutyouth-main {
    padding-bottom: 550px;
    padding-top: 500px;

}
.title-main-div-youth {
    padding-top: 30px;
    padding-left: 0px;
 

 
    
}
.title-large-youth {
    text-align: left;
}


.title-small-youth {
    color: #fc6539;
    
    
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #ef5f34;
    line-height: 2;
    
   
    position: relative;
}
.title-large-youth {
    font-family: "Yeseva One", cursive;
    font-size: 45px;
}

.span-about-youth {
    background: #fea444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.aboutcontent-left-youth {
  
    height: 500px;
    width: 100%;
    
}


.img1-youth {
    margin-top: -680px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 450px;
}
.img2-youth {
    padding-left: 20px;
    padding-top: 70px;
}

.aboutcontent-right-youth {
  
    height: 500px;
    width: 100%;
    margin-top: 20px;
    margin-left:-30px 
}

.aboutright-top-youth {
    display: flex;
}

.linee-youth {
    height: 73px;
    margin-top: 600px;
    width: 12px;
    background-color: #FC6539;
    
}

.abouttextmain-youth {
    font-size: 22px;
    font-family: "Yeseva One", cursive;
    margin-left: 0px;
    padding-top: 420px;
}

.abouttextfirst-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    padding-left: 40px;
    margin-top: -60px;
    line-height: 22px;
}
.abouttextsecond-youth {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #595959;
    line-height: 22px;
    padding-left: 36px;
}

.listabout-youth {
    list-style: none;
    padding-top: 10px;
}

.listabout-youth li {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #001d23;
    font-weight: 600;
    letter-spacing: 1px;
}

.about-buttonn-youth {
    
    margin-left: 40px;
    margin-right: -20px;
    border-radius:30px;
    padding-top: 10px ;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FEA444;
    background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}

.butt-span-youth{
    font-size: 20px;
    font-weight: 500;
    font-family: "Playfair Display", sans-serif;
    
   
}

.hline-youth {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.toppp-youth  {
    display: flex;
    justify-content: flex-end;
    
} */
}


@media only screen and (min-device-width: 800px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait) {

    
    .donyouth {
        padding-bottom: 250px;
    }
    
    .aboutdon-main {
        padding-bottom: 370px;
        padding-top: 0px;
    }
    .title-main-div-don {
        padding-top: 30px;
        padding-left: 0px;
        
    }
    
    .title-small-don {
        color: #fc6539;
    
        display: inline-block;
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-don {
        font-family: "Yeseva One", cursive;
        font-size: 60px;
        padding-bottom: 15px;
    }
    
    .span-about-don {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-don {
      
        height: 500px;
        width: 100%;
       
        
    }
    
    
    .img1-don {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-don {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-don {
      
        height: 500px;
        width: 100%;
        margin-top: 0px;
        margin-left:0px 
    }
    
    .aboutright-top-don {
        display: flex;
    }
    
    .linee-don {
        height: 120px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-don {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 20px;
        
    
    }
    
    .abouttextfirst-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 22px;
    }
    .abouttextsecond-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout-don {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-don li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-don {
        margin-left: 0px;
    
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-don {
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-don {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-don  {
        display: flex;
    }
    
/*     
    
    .aboutyouth-main {
        padding-bottom: 520px;
        padding-top: 100px;
    
    }
    .title-main-div-youth {
        padding-top: 30px;
        padding-left: 0px;
     
    
     
        
    }
    .title-large-youth {
        text-align: left;
    }
    
    
    .title-small-youth {
        color: #fc6539;
        
        
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-youth {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
    }
    
    .span-about-youth {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-youth {
      
        height: 500px;
        width: 100%;
        
        
    }
    
    
    .img1-youth {
        margin-top: 00px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-youth {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-youth {
      
        height: 500px;
        width: 100%;
        margin-top: 20px;
        margin-left:-30px 
    }
    
    .aboutright-top-youth {
        display: flex;
    }
    
    .linee-youth {
        height: 170px;
        margin-top: 0px;
        width: 15px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-youth {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        margin-left: 0px;
        padding-top: 00px;
    }
    
    .abouttextfirst-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 40px;
        
        line-height: 22px;
    }
    .abouttextsecond-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 36px;
    }
    
    .listabout-youth {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-youth li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-youth {
        
        margin-left: 40px;
        margin-right: -20px;
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-youth{
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-youth {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-youth  {
        display: flex;
        justify-content: flex-end;
        
    }
    
 */




}


@media (min-width: 760px) and (max-width: 1020px) and (orientation: portrait) {


    
    .donyouth {
        padding-bottom: 250px;
    }
    .aboutdon-main {
        padding-bottom: 370px;
        padding-top: 0px;
    }
    .title-main-div-don {
        padding-top: 30px;
        padding-left: 0px;
        
    }
    
    .title-small-don {
        color: #fc6539;
    
        display: inline-block;
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-don {
        font-family: "Yeseva One", cursive;
        font-size: 60px;
        padding-bottom: 15px;
    }
    
    .span-about-don {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-don {
      
        height: 500px;
        width: 100%;
       
        
    }
    
    
    .img1-don {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-don {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-don {
      
        height: 500px;
        width: 100%;
        margin-top: 0px;
        margin-left:0px 
    }
    
    .aboutright-top-don {
        display: flex;
    }
    
    .linee-don {
        height: 120px;
        margin-top: 0px;
        width: 12px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-don {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        padding-left: 20px;
        
    
    }
    
    .abouttextfirst-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 22px;
    }
    .abouttextsecond-don {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .listabout-don {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-don li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-don {
        margin-left: 0px;
    
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-don {
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-don {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-don  {
        display: flex;
    }
    
/*     
    
    .aboutyouth-main {
        padding-bottom: 520px;
        padding-top: 100px;
    
    }
    .title-main-div-youth {
        padding-top: 30px;
        padding-left: 0px;
     
    
     
        
    }
    .title-large-youth {
        text-align: left;
    }
    
    
    .title-small-youth {
        color: #fc6539;
        
        
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: #ef5f34;
        line-height: 2;
        
       
        position: relative;
    }
    .title-large-youth {
        font-family: "Yeseva One", cursive;
        font-size: 50px;
    }
    
    .span-about-youth {
        background: #fea444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }
    
    .aboutcontent-left-youth {
      
        height: 500px;
        width: 100%;
        
        
    }
    
    
    .img1-youth {
        margin-top: 00px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 480px;
    }
    .img2-youth {
        padding-left: 20px;
        padding-top: 70px;
    }
    
    .aboutcontent-right-youth {
      
        height: 500px;
        width: 100%;
        margin-top: 20px;
        margin-left:-30px 
    }
    
    .aboutright-top-youth {
        display: flex;
    }
    
    .linee-youth {
        height: 170px;
        margin-top: 0px;
        width: 15px;
        background-color: #FC6539;
        
    }
    
    .abouttextmain-youth {
        font-size: 22px;
        font-family: "Yeseva One", cursive;
        margin-left: 0px;
        padding-top: 00px;
    }
    
    .abouttextfirst-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        padding-left: 40px;
        
        line-height: 22px;
    }
    .abouttextsecond-youth {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #595959;
        line-height: 22px;
        padding-left: 36px;
    }
    
    .listabout-youth {
        list-style: none;
        padding-top: 10px;
    }
    
    .listabout-youth li {
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #001d23;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .about-buttonn-youth {
        
        margin-left: 40px;
        margin-right: -20px;
        border-radius:30px;
        padding-top: 10px ;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background: #FEA444;
        background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
    }
    
    .butt-span-youth{
        font-size: 20px;
        font-weight: 500;
        font-family: "Playfair Display", sans-serif;
        
       
    }
    
    .hline-youth {
        background-color: #fc6539;
        height: 5px;
        width: 40px;
        margin-right: 20px;
        margin-top: 16px;
    }
    
    .toppp-youth  {
        display: flex;
        justify-content: flex-end;
        
    }
    
 */



}