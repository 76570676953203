/* EventModal.css */
.event-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    ; /* Set a higher z-index */

  }
  
  .event-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    z-index: 1100; /* Set a higher z-index */
    display: flex;
  }
  
  .modal-content {
    display: flex;
  }
  
  .img-div-modal {
    width: 700px; /* Adjust the width as needed */
    height: auto;
    margin-right: 20px; /* Adjust the margin as needed */
  }
  
  .img-div-modal img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .text-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .popup-button{
    margin-top: 10px;
    width: 140px;
    height: 40px; 
    
  }
  

  .popup-title {
    font-family: "Roboto Slab", serif;
    font-size: 30px;
  }

  .popup-desc {
    font-family: "Mulish", sans-serif;
  }

  .popup-description  {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #ef5f34;;
  }


  .popup-outcomet {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #ef5f34;;
  }

  @media only screen and (max-width: 767px) { 



.event-modal-overlay {
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    }
  
  .event-modal {
    position: fixed;
    background-color: #fff;
    padding: 20px;
    
    z-index: 1100; 
    display: flex;
    flex-direction: column;
    
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    
  }
  
  .img-div-modal {
    display: none;
    width: 390px;
    /* Adjust the width as needed */
    height: 300px;
     /* Adjust the margin as needed */
  }
  
  .img-div-modal img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .text-content {
    
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .popup-button{
    margin-top: 10px;
    width: 140px;
    height: 40px; 
    
  }
  

  .popup-title {
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    margin-top: -25px;
  }

  .popup-desc {
    font-family: "Mulish", sans-serif;
  }

  .popup-description  {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #ef5f34;;
  }


  .popup-outcomet {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #ef5f34;
    margin-top: -10px;
  }

  }