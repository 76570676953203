.main-donation-div {
    padding-top: 20px;
    padding-bottom: 33px;
    background: linear-gradient(to left, rgba(239, 95, 52, 0.75), #ed4918);
    background-size: cover;
    background-position: center;
    border-radius: 23px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 100px;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    position: relative;
}


.shape-img {
    position: absolute;
    
    top: -60%;
    left: -10%;
    right:10%;
    width: 110%;
    height: 190%; /* Ensure the image covers the entire width */
    z-index: 3; /* Place the image behind other content */
}

/* ... rest of your CSS ... */

.name-donation {
    font-size: 25px;
    font-family: "Roboto Slab", serif;
    color: white
}

.donation-contents span {
    font-family: "roboto slab", serif;
    font-size: 50px;
    color: white;
}
.icon-div {
    color: white;
}





@media only screen and (max-width: 768px) {

    .main-donation-div {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #fcbf48;
        border-radius: 23px;
        margin-left: 60px;
        margin-right: 60px;
        margin-top:270px;
        margin-bottom: 0px;
        
    }
    
    
}


@media only screen and (max-device-width: 375px)

{



 .main-donation-div {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #fcbf48;
        border-radius: 23px;
        margin-left: 80px;
        margin-right: 90px;
        margin-top:320px;
        margin-bottom: 0px;
        
    }
    

    .shape-img {
        position: absolute;
        
        top: -60%;
        left: -10%;
        right:10%;
        width: 110%;
        height: 190%; /* Ensure the image covers the entire width */
        z-index: 3; /* Place the image behind other content */
    }
    
    /* ... rest of your CSS ... */
    
    .name-donation {
        font-size: 20px;
        font-family: "Roboto Slab", serif;
        color: white
    }
    
    .donation-contents span {
        font-family: "roboto slab", serif;
        font-size: 30px;
        color: white;
    }
    .icon-div {
        color: white;
    }
    

}

@media only screen and (min-device-width: 760px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait) {

    .main-donation-div {
        padding-top: 10px;
        padding-bottom: 20px;
        background-color: #fcbf48;
        border-radius: 23px;
        margin-left: 60px;
        margin-right: 60px;
        margin-top:70px;
        margin-bottom: 0px;
        
    }

    
.shape-img {
    position: absolute;
    
    top: -60%;
    left: -10%;
    right:10%;
    width: 110%;
    height: 190%; /* Ensure the image covers the entire width */
    z-index: 3; /* Place the image behind other content */
}

/* ... rest of your CSS ... */

.name-donation {
    font-size: 24px;
    font-family: "Roboto Slab", serif;
    color: white
}

.donation-contents span {
    font-family: "roboto slab", serif;
    font-size: 30px;
    color: white;
}
.icon-div {
    color: white;
}

    

}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {


    .main-donation-div {
        padding-top: 10px;
        padding-bottom: 20px;
        background-color: #fcbf48;
        border-radius: 23px;
        margin-left: 60px;
        margin-right: 60px;
        margin-top:70px;
        margin-bottom: 0px;
        
    }

    
.shape-img {
    position: absolute;
    
    top: -60%;
    left: -10%;
    right:10%;
    width: 110%;
    height: 190%; /* Ensure the image covers the entire width */
    z-index: 3; /* Place the image behind other content */
}

/* ... rest of your CSS ... */

.name-donation {
    font-size: 24px;
    font-family: "Roboto Slab", serif;
    color: white
}

.donation-contents span {
    font-family: "roboto slab", serif;
    font-size: 30px;
    color: white;
}
.icon-div {
    color: white;
}


}
