@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Yeseva+One&display=swap');

.carousel-item {
    position: relative;
    margin-top:80px;
  
}

.carousel-item img {
    height: 700px;
    width: 100%;
    object-fit: cover;
    
}

.carousel-item .purplebg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 47%;
    height: 700px;

}

.carousel-item .hero-content {
    position: absolute;
    top: 10%; /* Adjust as needed */
    left: 4%; /* Adjust as needed */
    
    z-index: 2; /* Ensure a higher z-index than the other elements */
    text-align: left; /* Adjust as needed */
    color: white; 
/* Adjust the text color */
}

.carousel-item .hero-title {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 0px 0px 2px;
    letter-spacing: 2px;
    text-decoration: underline 1px;
    
}

.carousel-item .hero-text {
    font-size: 80px;
    font-family: 'Yeseva One', serif;
    font-weight: 700;
   

    
    
}
.kolar {
    color:rgb(58, 50, 58);
}

.greeni {
    color:rgb(58, 50, 58);
}

.carousel-item .hero-button {
    margin-top: 12px;
    background-color: #F1AE44;
    padding-top:7px ;
    padding-bottom: 7px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left:15px;
}

.carousel-item .hero-button span {
    color: aliceblue;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px ;
}

.hero-content .hero-text {
   
    width: 60%;
    line-height: 100px;
}

.mobile-img {
    display: none;
}

.carousel-control-prev {
    left: -100px; /* Adjust as needed */
   
  }
.carousel-control-next {
  left: 1280px; /* Adjust as needed */
   /* Adjust as needed */
}





@media only screen and (max-width: 767px) {

    .carousel-item {
        position: relative;
        margin-top: 100px;
        padding-bottom: 100px;
        margin-bottom: -40px;
    }
    .carousel-item img {
        height: 600px;
        width: 100%;
        object-fit: cover;
    

    }
    .carousel-item .purplebg {
    
        position: absolute;
        top: 0;
        left:-200px;
    
        
        
        
        z-index: 1;
        width: 200%;
        height:600px;
    
    }
    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 5%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
        /* Adjust the text color */
    }
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding-top: 80px  ;
        letter-spacing: 1px;
        text-decoration: underline 1px;
    
        
    }

    .kolar {
        display: block;
        color: white;
    }
    
    .greeni {
        display: block;
        color: white;
    }

  
    .carousel-item .hero-text {
        font-size: 45px;
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        
        line-height: 55px;
        width: 80%;
        padding-top: 10px;
        color: white;
    }
    .carousel-item .hero-button {
        margin-top: 30px;
        background-color: #F1AE44;
        padding-top:2px ;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .carousel-item .hero-button span {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 13px ;
    }
    .laptop-img {
        display: none;
    }

    .mobile-img {
        display: block;
    }

    .carousel-control-prev {
        left: -5%;
        right: 0%; /* Adjust as needed */
        display: none;
       
      }
    .carousel-control-next {
      left: 90%;
      right: 0%; /* Adjust as needed */
      display: none;
       /* Adjust as needed */
    }

    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 2%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
    /* Adjust the text color */
    }
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top:7px ;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left:10px;
    }
    
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {

        
    .carousel-item {
        position: relative;
        margin-top:90px;
      
    }
    
    .carousel-item img {
        height: 700px;
        width: 100%;
        object-fit: cover;
        
    }
    
    .carousel-item .purplebg {
        position: absolute;
        top: 0;
        left:-450px;
    
        z-index: 1;
        width: 100%;
        height: 900px;
    
    }
    
    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 4%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
    /* Adjust the text color */
    }
    
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding: 0px 0px 2px;
        letter-spacing: 2px;
        text-decoration: underline 1px;
        
    }
    
    .carousel-item .hero-text {
        font-size: 70px;
        font-family: 'Yeseva One', serif;
        font-weight: 700;
        }
    .kolar {
        color:rgb(58, 50, 58);
    }
    
    .greeni {
        color:rgb(58, 50, 58);
    }
    
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top:7px ;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left:15px;
    }
    
    .carousel-item .hero-button span {
        color: aliceblue;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px ;
    }
    
    .hero-content .hero-text {
       
        width: 60%;
        line-height: 100px;
    }
    
    .mobile-img {
        display: none;
    }
    
    .carousel-control-prev {
        left: -60px; /* Adjust as needed */
       
      }
    .carousel-control-next {
      left: 850px; /* Adjust as needed */
       /* Adjust as needed */
    }
    
    


 }

 @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

        
    .carousel-item {
        position: relative;
        margin-top:90px;
      
    }
    
    .carousel-item img {
        height: 700px;
        width: 100%;
        object-fit: cover;
        
    }
    
    .carousel-item .purplebg {
        position: absolute;
        top: 0;
        left:-570px;
    
        z-index: 1;
        width: 100%;
        height: 900px;
    
    }
    
    .carousel-item .hero-content {
        position: absolute;
        top: 15%; /* Adjust as needed */
        left: 4%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
    /* Adjust the text color */
    }
    
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding: 0px 0px 2px;
        letter-spacing: 2px;
        text-decoration: underline 1px;
        
    }
    
    .carousel-item .hero-text {
        font-size: 80px;
        font-family: 'Yeseva One', serif;
        font-weight: 700;
        }
    .kolar {
        color:rgb(58, 50, 58);
    }
    
    .greeni {
        color:rgb(58, 50, 58);
    }
    
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top:7px ;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left:15px;
    }
    
    .carousel-item .hero-button span {
        color: aliceblue;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px ;
    }
    
    .hero-content .hero-text {
       
        width: 60%;
        line-height: 100px;
    }
    
    .mobile-img {
        display: none;
    }
    
    .carousel-control-prev {
        left: -80px; /* Adjust as needed */
       
      }
    .carousel-control-next {
      left: 980px;
     /* Adjust as needed */
       /* Adjust as needed */
    }
    
    


 }


 @media (min-width: 760px) and (max-width: 1020px) and (orientation: portrait) {
    
        
    .carousel-item {
        position: relative;
        margin-top:80px;
      
    }
    
    .carousel-item img {
        height: 700px;
        width: 100%;
        object-fit: cover;
        
    }
    
    .carousel-item .purplebg {
        position: absolute;
        top: 0;
        left:-570px;
    
        z-index: 1;
        width: 130%;
        height: 900px;
    
    }
    
    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 4%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
    /* Adjust the text color */
    }
    
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding: 0px 0px 2px;
        letter-spacing: 2px;
        text-decoration: underline 1px;
        
    }
    
    .carousel-item .hero-text {
        font-size: 60px;
        font-family: 'Yeseva One', serif;
        font-weight: 700;
        }
    .kolar {
        color:rgb(58, 50, 58);
    }
    
    .greeni {
        color:rgb(58, 50, 58);
    }
    
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top:7px ;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left:15px;
    }
    
    .carousel-item .hero-button span {
        color: aliceblue;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px ;
    }
    
    .hero-content .hero-text {
       
        width: 60%;
        line-height: 100px;
    }
    
    .mobile-img {
        display: none;
    }
    
    .carousel-control-prev {
        left: -40px; /* Adjust as needed */
       
      }
    .carousel-control-next {
      left: 650px;
    }
    
 }
 @media only screen and (min-device-width: 744px) and (max-device-width: 1133px) and (orientation: landscape) {
    .carousel-item {
        position: relative;
        margin-top: 90px;
    }
    
    .carousel-item img {
        height: 700px;
        width: 100%;
        object-fit: cover;
    }
    
    .carousel-item .purplebg {
        position: absolute;
        top: 0;
        left: -570px;
        z-index: 1;
        width: 110%;
        height: 900px;
    }
    
    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 4%; /* Adjust as needed */
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
        /* Adjust the text color */
    }
    
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding: 0px 0px 2px;
        letter-spacing: 2px;
        text-decoration: underline 1px;
    }
    
    .carousel-item .hero-text {
        font-size: 60px;
        font-family: 'Yeseva One', serif;
        font-weight: 700;
    }
    
    .kolar {
        color: rgb(58, 50, 58);
    }
    
    .greeni {
        color: rgb(58, 50, 58);
    }
    
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: 15px;
    }
    
    .carousel-item .hero-button span {
        color: aliceblue;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
    }
    
    .hero-content .hero-text {
        width: 60%;
        line-height: 100px;
    }
    
    .mobile-img {
        display: none;
    }
    
    .carousel-control-prev {
        left: -40px; /* Adjust as needed */
    }
    
    .carousel-control-next {
        left: 650px; /* Adjust as needed */
    }
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 375px) {

   
    .carousel-item {
        position: relative;
        margin-top: 100px;
        padding-bottom: 100px;
        margin-bottom: -40px;
    }
    .carousel-item img {
        height: 600px;
        width: 100%;
        object-fit: cover;
    

    }
    .carousel-item .purplebg {
    
        position: absolute;
        top: 0;
        left:-200px;
    
        
        
        
        z-index: 1;
        width: 200%;
        height:600px;
    
    }
    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 5%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
        /* Adjust the text color */
    }
    .carousel-item .hero-title {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        padding-top: 20px  ;
        letter-spacing: 1px;
        text-decoration: underline 1px;
    
        
    }

    .kolar {
        display: block;
        color: white;
    }
    
    .greeni {
        display: block;
        color: white;
    }

  
    .carousel-item .hero-text {
        font-size: 45px;
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        
        line-height: 55px;
        width: 80%;
        padding-top: 10px;
        color: white;
    }
    .carousel-item .hero-button {
        margin-top: 30px;
        background-color: #F1AE44;
        padding-top:2px ;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .carousel-item .hero-button span {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 13px ;
    }
    .laptop-img {
        display: none;
    }

    .mobile-img {
        display: block;
    }

    .carousel-control-prev {
        left: -5%;
        right: 0%; /* Adjust as needed */
        display: none;
       
      }
    .carousel-control-next {
      left: 90%;
      right: 0%; /* Adjust as needed */
      display: none;
       /* Adjust as needed */
    }

    .carousel-item .hero-content {
        position: absolute;
        top: 10%; /* Adjust as needed */
        left: 2%; /* Adjust as needed */
        
        z-index: 2; /* Ensure a higher z-index than the other elements */
        text-align: left; /* Adjust as needed */
        color: white; 
    /* Adjust the text color */
    }
    .carousel-item .hero-button {
        margin-top: 12px;
        background-color: #F1AE44;
        padding-top:7px ;
        padding-bottom: 7px;
        padding-left: 30px;
        padding-right: 30px;
        margin-left:10px;
    }
    
}


  