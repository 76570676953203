.socialintro-main-div {
    padding-top: 40px;
}


.socialintro-main-div .socialintro-header {
    padding-bottom: 40px;
    
}

.socialintro-main-div .socialintro-header .socialintro-small-text {
    font-size: 40px;
    font-family: "Alex Brush", serif;
    color: #ef5f34;
}

.socialintro-main-div .socialintro-header .socialintro-title {
    font-size: 40px;
    font-family:"Roboto Slab", serif ;
    color: #212158;
    font-weight: 400;
}

.img-socialintro {
    width: 490px;
    height: 420px;
    
}

.img-socialintro  img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    
}

.socialintro-contents {
    color: #4f555a;
        font-family: "Mulish", sans-serif;
        
        font-size: 19px;
}


@media only screen and (max-width: 767px)

{
    .img-socialintro {
        width: 400px;
        height: 380px;
        
    }


}