.contact-infos {
    padding-top: 55px;
}

.icon-contact {
    padding-bottom: 30px;
    color: #5dc4b8;
}

.contact-title {
    margin-bottom: 20px;
    font-size: 30px;
    font-family: 'Roboto Slab', serif;
    color: #212158;
}

.contact-text {
    color: #4F555A;
    font-family: "Mulish", serif;
    font-size: 16px;
}

.person-name {
    padding-top: 20px;
    font-family: "Roboto Slab", sans-serif;
}