.main-involved-div {
   
    padding-top: 30px;
}

.join-titlepart {
  padding-left: 100px;
}
.firstpartt {
  display: flex;
}

  .hline1 {
    background-color: #fc6539;
    height: 5px;
    width: 40px;
    margin-right: 20px;
    margin-top: 16px;
}

.title-small1 {
 color:  #fc6539;
 padding-top: 5px;
}


.title-join {
  font-family:"Yeseva One", cursive;
  font-size: 60px;

  width: 80%;
  
}

.title-join-span {
  background: none;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #939697;
    -webkit-text-fill-color: unset;
}

.join-us-text {
  color: #595959;
  font-family: "Roboto", sans-serif;
  width: 88%;
}

.form-part-inv1 {

  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
margin-bottom: 30px;

}


.enpoot1 {
  width: 300px;
 
  background-color: transparent;
    border: 2px solid #d7d7d7;
    border-radius: 0;
    box-shadow: none;
    color: #001d23;
    height: 55px;
    padding: 20px 20px;
}
.form-part-inv2 {

  width: 100%;
  height: 40px;

 
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 30px;


}


.enpoot1 {
  width: 320px;
  
}

.form-part-inv3 {

  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
margin-bottom: 20px;

}

.enpoot2 {
  width: 100%;
  height: 200px;
  background-color: transparent;
    border: 2px solid #d7d7d7;
    border-radius: 0;
    box-shadow: none;
    color: #001d23;
  
    padding: 20px 20px;
  
  
}

.button-dib {
padding-left: 70px;
margin-top:20px;

}


.cvgaiz {
  padding-left: 100px;
  margin-top: 155px;
}

.about-buttonnn {
  margin-left: 32px;
  border-radius:30px;
  padding-top: 10px ;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #FEA444;
  background: linear-gradient(to right, #FEA444 0%, #FC6539 100%);
}


.cta-section-2 {
  background-image: url(	https://charius-react.wpolive.com/static/media/bg.9fd584eb6cdae890cf90.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  position: relative;
  height: 500px;
  margin-left: -112px;
  margin-right: -112px;
  z-index: 1;
  margin-top: 50px;  
}

.bg-overlay {
  background-color: #ff8626;
  width: 500px;
  height: 400px;
}